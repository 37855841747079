<template>
  <div class="academic-years">
    <lenon-list-page
      title="Arrears List"
      :columns="columns"
      :rows="arrears"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      :remote="true"
      search-placeholder="Student ID or Name"
      :show-profile-photo="true"
    >
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="arrears"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedClassId"
                  placeholder="Select Class"
                  :options="classes"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedFeeSettingIds"
                  style="max-width: 150px !important;"
                  placeholder="Fee Setting"
                  :options="filteredFeeSettings"
                  label-name="display_name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6 mb-1">
                <div class="row">
                  <div class="col-6">
                    <lenon-button
                      icon="MessageCircleIcon"
                      label="Notify"
                      variant="outline-primary"
                      tool-tip-text="Notify parents on student arrears"
                      @onClick="handleArrearsActionClick()"
                    />
                  </div>
                  <div class="col-6">
                    <lenon-button
                      icon="ListIcon"
                      label="List"
                      tool-tip-text="Generate Arrears List"
                      @onClick="handleArrearsActionClick(true)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12 mb-1">
          <div class="row">
            <div class="col-md-4">
              <strong>Total Expected:
                <b-badge
                  variant="light-primary"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>{{ totalExpectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Collected:
                <b-badge
                  variant="light-success"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>+{{ totalCollectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Pending:
                <b-badge
                  variant="light-danger"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>-{{ totalPendingAmount | currency }}
                </b-badge>
              </strong>
            </div>
          </div>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedFeeSettingIds"
            style="max-width: 150px !important;"
            placeholder="Fee Setting"
            :options="filteredFeeSettings"
            label-name="display_name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            icon="MessageCircleIcon"
            label="Notify"
            variant="outline-primary"
            tool-tip-text="Notify parents on student arrears"
            class="mr-1"
            @onClick="handleArrearsActionClick()"
          />
          <lenon-button
            icon="ListIcon"
            label="List"
            tool-tip-text="Generate Arrears List"
            @onClick="handleArrearsActionClick(true)"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.arrears
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template #amount_paid="{row}">
        {{ row.item.amount_paid | currency }}

      </template>
      <template #arrears="{row}">
        {{ row.item.arrears | currency }}

      </template>
      <template #total_amount="{row}">
        {{ row.item.total_amount | currency }}

      </template>
      <template #parent_name="{row}">
        <label v-if="row.item.parent_name">
          {{ row.item.parent_name }}({{ getRelation(row.item.relation) }})
        </label>
        <b-badge
          v-else
          variant="light-primary"
        >
          N/A
        </b-badge>
      </template>
      <template #parent_phone="{row}">
        <label v-if="row.item.parent_phone">
          <a :href="`tel:${row.item.parent_phone}`">{{ row.item.parent_phone }}</a>
        </label>
        <b-badge
          v-else
          variant="light-primary"
        >
          N/A
        </b-badge>
      </template>
    </lenon-list-page>
    <validation-observer
      ref="feePaymentForm"
      v-slot="{invalid}"
    >
      <lenon-modal
        :title="arrearsMode?'Generate Arrears List':'Send Arrears Notifications - SMS & PUSH'"
        :show="smsNotificationModalOpened"
        :show-overlay="sendingNotifications"
        size="md"
        @onClose="smsNotificationModalOpened=false"
      >
        <div class="row mb-1">
          <div
            v-if="!arrearsMode"
            class="col-12"
          >
            Please select <b>classes</b> and <b>fee types</b> to send notifications
            (<strong>Parents with the mobile app will receive free push notifications</strong>)
          </div>
          <div
            v-else
            class="col-12"
          >
            Please select <b>classes</b> and <b>fee types</b> to generate arrears list
          </div>
        </div>
        <lenon-select
          v-model="selectedClassId"
          label-name="name"
          value-name="id"
          :options="classes"
          label="Classes"
          rules="required"
        />
        <div class="mt-1" />
        <lenon-select
          v-model="selectedFeeSettingIds"
          label-name="title"
          value-name="id"
          :options="filteredFeeSettings"
          label="Fee Types"
          rules="required"
        />

        <template slot="modal-actions">
          <div class="row float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="smsNotificationModalOpened=false"
            />
            <lenon-button
              :label="arrearsMode?'Generate':'Send'"
              :disabled="invalid"
              :loading="arrearsMode?generating:sendingNotifications"
              loading-text="Loading..."
              @onClick="arrearsMode?generateList():sendNotifications()"
            />
          </div>
        </template>
      </lenon-modal>
    </validation-observer>

  </div>
</template>

<script>

import { BBadge, VBToggle, BCollapse } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_STUDENT_ARREARS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import { GENERATE_ARREARS_LIST_M, SEND_ARREARS_NOTIFICATION_M } from '@/graphql/mutations'
import parentRelations from '@/lenon/mixins/parentRelations'

export default {
  name: 'FeePayments',
  components: {
    LenonModal,
    LenonMultiSelect,
    LenonSelect,
    LenonListPage,
    LenonButton,
    ValidationObserver,
    BBadge,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast, confirm, parentRelations],
  data() {
    return {
      arrearsMode: false,
      sendingNotifications: false,
      smsNotificationModalOpened: false,
      feePaymentModalOpened: false,
      tableLoading: false,
      studentFeePayment: null,
      selectedClassesForModal: [],
      selectedFeeSettingIdsForModal: [],
      selectedFees: [],
      selectedFeeSettingIds: null,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      initialLoad: true,
      generating: false,
    }
  },
  computed: {
    columns() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Full Name',
          sortable: show,
        },
        {
          key: 'total_amount',
          label: `Total Amount(${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'amount_paid',
          label: `Amount Paid(${this.$store.getters['auth/currency']})`,
          sortable: show,
        },
        {
          key: 'arrears',
          label: `Arrears(${this.$store.getters['auth/currency']})`,
          sortable: show,
        },
        {
          key: 'fee_type',
          label: 'Fee Type',
        },
        {
          key: 'due_date',
          label: 'Due Date',
        },
        {
          key: 'parent_name',
          label: 'Parent',
        },
        {
          key: 'parent_phone',
          label: 'Contact',
        },
      ]
    },
    totalExpectedAmount() {
      return this.arrears.reduce((accumulator, currentValue) => accumulator + (+currentValue.total_amount), 0)
    },
    totalCollectedAmount() {
      return this.arrears.reduce((accumulator, currentValue) => accumulator + (+currentValue.amount_paid), 0)
    },
    totalPendingAmount() {
      return this.arrears.reduce((accumulator, currentValue) => accumulator + (+currentValue.arrears), 0)
    },
    monitorSettings() {
      return this.selectedAcademicYearId
          && this.selectedTermId
          && this.selectedClassId
    },
    previousSetting() {
      return this.$store.getters['feePayments/previousArrearsSettings']
    },
    arrears() {
      return this.$store.getters['feePayments/arrears']
    },
    feeSettings() {
      return this.$store.getters['feeSettings/feeSettings']
    },
    classes() {
      if (!this.selectedTerm) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    filteredFeeSettings() {
      return this.feeSettings.filter(fs => {
        if (this.selectedAcademicYearId && this.selectedTermId && this.selectedClassId) {
          return ((fs.academic_year_id === this.selectedAcademicYearId)
              && (fs.term_id === this.selectedTerm.id)
              && (fs.classes.includes(this.selectedClassId)))
        }
        return false
      })
    },
  },
  watch: {
    monitorSettings() {
      this.selectedFeeSettingIds = null
      // this.$store.commit('feePayments/setArrears', {
      //   arrears: { data: [] },
      // })
    },
    selectedFeeSettingIds(ids) {
      if ((ids || this.searchTerm)) {
        this.$store.commit('feePayments/setArrearsSettings', {
          selectedFeeSettingIds: this.selectedFeeSettingIds,
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
          search: this.searchTerm,
        })
        this.fetchArrears()
      } else if (!this.searchTerm) {
        this.$store.commit('feePayments/setArrears', {
          arrears: { data: [] },
        })
      }
    },
    // monitorSettings(valid) {
    //   if (valid) {
    //     this.$store.commit('feePayments/setArrearsSettings', {
    //       selectedFeeSettingIds: this.selectedFeeSettingIds,
    //       selectedAcademicYearId: this.selectedAcademicYearId,
    //       selectedTermId: this.selectedTermId,
    //       selectedClassId: this.selectedClassId,
    //     })
    //     this.fetchArrears()
    //   } else {
    //     this.$store.commit('feePayments/setArrears', {
    //       arrears: { data: [] },
    //     })
    //   }
    // },
    filteredFeeSettings(feeSettings) {
      this.selectedFeeSettingId = null
      if (!this.searchTerm && !this.initialLoad) {
        this.$store.commit('feePayments/setArrears', {
          arrears: { data: [] },
        })
      }
    },
  },
  mounted() {
    this.setSettings()
    // this.$store.commit('feePayments/setArrears', {
    //   arrears: { data: [] },
    // })
  },
  methods: {
    getRelation(relation) {
      const rel = this.relations.find(re => re.value === relation)
      return rel.label
    },
    sendNotifications() {
      this.sendingNotifications = true
      this.$apollo.mutate({
        mutation: SEND_ARREARS_NOTIFICATION_M,
        variables: {
          input: {
            fee_setting_id: this.selectedFeeSettingIds,
            class_id: this.selectedClassId,
          },
        },
      })
        .then(res => {
          this.sendingNotifications = false
          if (res.errors) {
            this.showError('Something went wrong, please try again')
          }
          this.showSuccess('Notifications are being sent')
        })
        .catch(err => {
          this.sendingNotifications = false
          this.showError('Something went wrong, please try again')
        })
    },
    generateList() {
      this.generating = true
      this.$apollo.mutate({
        mutation: GENERATE_ARREARS_LIST_M,
        variables: {
          input: {
            fee_setting_id: this.selectedFeeSettingIds,
            class_id: this.selectedClassId,
          },
        },
      })
        .then(res => {
          this.generating = false
          if (res.errors) {
            this.showError('Something went wrong, please try again')
          }
          if (!res.data.generateArrearsList) {
            this.showError('List was not generated, make sure there are students in arrears')
          }
          this.downloadFile(res.data.generateArrearsList, 'Arrears_List.pdf')
        })
        .catch(err => {
          this.generating = false
          this.showError('Something went wrong, please try again')
        })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    handleArrearsActionClick(arrears = false) {
      if (this.selectedFeeSettingIds) {
        this.smsNotificationModalOpened = true
        this.arrearsMode = arrears
        const foundClass = this.classes.find(cl => cl?.id === this.selectedClassId)
        this.selectedClassesForModal = foundClass ? [foundClass] : []
        this.selectedFeeSettingIdsForModal = [this.feeSettings.find(fs => fs.id === this.selectedFeeSettingIds)]
      } else {
        this.showInfo('Please select a Fee Setting')
      }
    },
    setSettings() {
      this.searchTerm = this.previousSetting?.search
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
      this.selectedClassId = this.previousSetting?.selectedClassId
      this.selectedFeeSettingIds = this.previousSetting?.selectedFeeSettingIds
      setTimeout(() => {
        this.selectedFeeSettingIds = this.previousSetting?.selectedFeeSettingIds
      }, 200)
    },
    fetchArrears() {
      if ((this.selectedFeeSettingIds || this.searchTerm) && this.selectedClassId) {
        this.tableLoading = (this.initialLoad ? this.arrears.length < 1 : true)
        this.$apollo.query({
          query: FETCH_STUDENT_ARREARS_Q,
          variables: {
            input: {
              fee_setting_id: this.selectedFeeSettingIds,
              class_id: this.selectedClassId,
            },
          },
        })
          .then(res => {
            if (res.data) {
              this.$store.commit('feePayments/setArrears', res.data)
            }
          })
          .catch(err => {
            this.showError('Something went wrong, please try again')
          }).finally(() => {
            this.tableLoading = false
            this.initialLoad = false
          })
      }
    },
  },
}
</script>
